<template>
  <div class="tc-read_questionnaire">
    <!-- 顶部功能 -->
    <div class="header-tool">
      <div class="del" @click="del">
        <img src="../../../assets/images/del.png" alt />
      </div>
      <div class="refresh" @click="refresh()">
        <img src="../../../assets/images/refresh.png" alt />
      </div>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border
        class="tableBox"
      >
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column prop="title" label="试卷名称" width="850" align="left" header-align="center"></el-table-column>
        <el-table-column prop="count" label="答题统计" width="150" align="center"></el-table-column>
        <el-table-column prop="count" label="参与人数" width="150" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="plain" @click="goRead(scope.row)">批阅</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { listCheckUserQue, softDeleteAnswer } from "@/api/questionnaire.js";
import { log } from "util";
export default {
  inject: ["reload"],
  data() {
    return {
      tableData: [],
      // 页码
      page: 1,
      pageSize: 15,
      totlo: 0,
      multipleSelection: []
    };
  },
  created() {
    this.loadListData();
  },
  methods: {
    // 获取全选ID
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let p = [];
      this.multipleSelection.forEach(v => {
        p.push(v.id);
      });
      this.multipleSelection = p;
    },
    // 数据加载
    loadListData() {
      let data = {
        page: this.page,
        pagesize: this.pageSize
      };
      listCheckUserQue(data).then(res => {
        this.tableData = res.data;
      });
    },
    // 前往批阅问卷
    goRead(row) {
      this.$router.push(`/read_questionnaire/read?id=${row.id}`);
    },
    // 删除问卷
    del(row) {
      let id = "";
      if (row.id) {
        id = row.id + "";
      } else {
        if (this.multipleSelection.length > 0) {
          id = this.multipleSelection.toString();
        } else {
          this.$message({
            showClose: true,
            message: "还未勾选要删除的问卷!",
            type: "warning"
          });
          return;
        }
      }
      this.$confirm("此操作将永久删除该问卷的答题记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          softDeleteAnswer({ qsid: id }).then(res => {
            if (res.code == 400200) {
              this.$message({
                type: "success",
                message: "删除成功!"
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 刷新
    refresh() {
      this.reload();
    }
  }
};
</script>

<style lang="less">
.tc-read_questionnaire {
  // 顶部功能
  .header-tool {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .del {
      width: 100px;
      margin-right: 20px;
      cursor: pointer;
    }
    .refresh {
      width: 100px;
      cursor: pointer;
    }
    img {
      width: 100%;
      pointer-events: none;
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 600px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
}
</style>